import '@audius/stems/dist/stems.css'
import React, { useState } from 'react'

import { Button, ButtonType, ButtonSize, Modal } from '@audius/stems'

import styles from './ConfirmModal.module.css'
import LoadingSpinner from './loading-spinner/LoadingSpinner'
import { TxState } from './types'

type ConfirmModalProps = {
  isOpen: boolean
  setIsModalOpen: (_: boolean) => void
  amount: number
  toWalletAddr: string
  sendWaudio: () => Promise<string | undefined>
}

export const ConfirmModal = ({
  isOpen,
  setIsModalOpen,
  amount,
  toWalletAddr,
  sendWaudio
}: ConfirmModalProps) => {
  const [txState, setTxState] = useState({ state: 'start' } as TxState)

  const onModalClose = () => {
    setIsModalOpen(false)
    setTxState({ state: 'start' })
  }

  const handleConfirmClick = async () => {
    setTxState({ state: 'loading' })
    try {
      const tx = await sendWaudio()
      if (tx !== undefined) {
        setTxState({
          state: 'success',
          txId: tx
        })
      } else {
        console.error('tx is undefined: ', tx)
        setTxState({
          state: 'error',
          errorMessage: 'tx is undefined'
        })
      }
    } catch (error) {
      setTxState({
        state: 'error',
        errorMessage: 'Server error'
      })
    }
  }

  const showTxState = () => {
    switch (txState.state) {
      case 'start':
        return (
          <>
            <h2 className={styles.h2}>Send {amount} wAudio to</h2>
            <p className={styles.wallet}>{toWalletAddr}</p>
            <Button
              onClick={handleConfirmClick}
              type={ButtonType.PRIMARY}
              size={ButtonSize.MEDIUM}
              text='Confirm'
            />
          </>
        )
      case 'loading':
        return (
          <>
            <h2 className={styles.h2}>Send {amount} wAudio to</h2>
            <p className={styles.wallet}>{toWalletAddr}</p>
            <LoadingSpinner />
          </>
        )
      case 'error':
        return (
          <>
            <h2 className={styles.h2}>Send {amount} wAudio to</h2>
            <p className={styles.wallet}>{toWalletAddr}</p>
            <p>{txState.errorMessage}</p>
          </>
        )
      case 'success':
        return (
          <>
            <h2 className={styles.h2}>Successfully sent {amount} wAudio to</h2>
            <p className={styles.wallet}>{toWalletAddr}</p>
            <a
              href={`https://solscan.io/tx/${txState.txId}`}
              target='_blank'
              rel='noreferrer'
              className={styles.link}
            >
              <Button
                type={ButtonType.PRIMARY}
                size={ButtonSize.MEDIUM}
                text='View the transaction on SolScan'
              />
            </a>
          </>
        )
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      bodyClassName={styles.container}
      onClose={onModalClose}
    >
      <div className={styles.confirm}>{showTxState()}</div>
    </Modal>
  )
}
