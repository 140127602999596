import { MouseEventHandler } from 'react'

import { User } from '@audius/sdk'

import '@audius/stems/dist/stems.css'

import styles from './UserTile.module.css'
import AudiusLogo from './assets/appIcon240.png'

const STAGING_AUDIUS_URL = 'https://staging.audius.co'

type Props = {
  user: User
  onClick: MouseEventHandler
}

type ProfilePicture = {
  '150x150': string
}

export const UserTile = ({ user, onClick }: Props) => {
  let profilePicture = null
  if (user.profile_picture) {
    profilePicture = user.profile_picture as ProfilePicture
  }
  return (
    <>
      <div className={styles.userTile} onClick={onClick}>
        <div className={styles.userHeader}>
          <a
            href={`${STAGING_AUDIUS_URL}/${user.handle}`}
            onClick={(e) => e.stopPropagation()} // Prevent parent from being clicked
            target='_blank'
            rel='noreferrer'
          >
            {user.handle}
          </a>
        </div>
        {
          <div>
            <img
              src={profilePicture ? profilePicture['150x150'] : AudiusLogo}
              alt='User profile picture'
              className={styles.profilePic}
            />
          </div>
        }
      </div>
    </>
  )
}
