import React from 'react'

import Lottie from 'react-lottie'

import loadingSpinner from '../assets/loadingSpinner.json'

import styles from './LoadingSpinner.module.css'

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingSpinner
}

const LoadingSpinner = () => {
  return (
    <div className={styles.container} role='progressbar'>
      <Lottie options={lottieOptions} />
    </div>
  )
}

export default LoadingSpinner
