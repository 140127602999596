import { useCallback, useState, ChangeEvent, FormEvent } from 'react'

import * as buffer from 'buffer'
import 'setimmediate'

import { sdk, User } from '@audius/sdk'
import '@audius/stems/dist/stems.css'
import '@audius/stems/dist/avenir.css'
import { Button, ButtonType, ButtonSize } from '@audius/stems'

import styles from './App.module.css'
import { ConfirmModal } from './ConfirmModal'
import { UserTile } from './UserTile'
import { sendWaudio } from './api'

window.Buffer = buffer.Buffer

const WAUDIO_CAP = 1000
const WALLET_ADDRESS_LENGTH = 44

const audiusSdk = sdk({
  appName: 'staging-waudio-faucet',
  identityServiceConfig: {
    identityServiceEndpoint: 'https://identityservice.staging.audius.co'
  },
  ethWeb3Config: {
    ownerWallet: '0x79f09a92A1819f8f4bF73ebb27F955F7DAf01F20',
    providers: ['https://eth.staging.audius.co']
  },
  ethContractsConfig: {
    tokenContractAddress: '0x5375BE4c52fA29b26077B0F15ee5254D779676A6',
    registryAddress: '0xF27A9c44d7d5DDdA29bC1eeaD94718EeAC1775e3',
    claimDistributionContractAddress:
      '0xb77228b1c32F777A787f33e13232015dB7B1D6F2',
    wormholeContractAddress: '0x6FB5Fd0916ec4a1e920B3350aCdC5EfBc14BA255'
  }
} as any)

function App() {
  const [amount, setAmount] = useState(10)
  const [userHandle, setUserHandle] = useState('')
  const [toWalletAddr, setToWalletAddr] = useState('')
  const [userList, setUserList] = useState<User[]>([])
  const [isCapped, setIsCapped] = useState(false)
  const [isFloored, setIsFloored] = useState(false)
  const [isNoResults, setIsNoResults] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsCapped(false)
    setIsFloored(false)
    const newAmount = Number(event.target.value)
    if (newAmount > WAUDIO_CAP) {
      setIsCapped(true)
    } else if (newAmount <= 0) {
      setIsFloored(true)
    } else {
      setAmount(newAmount)
    }
  }

  const handleUserHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserHandle(event.target.value)
  }

  const handleSearchClick = useCallback(async () => {
    setIsNoResults(false)
    setIsModalOpen(false)

    // In order to support both wallet or user handle input, first check
    // if the search returns no results, then if the input is the correct
    // length, treat it as a wallet address.
    const users = await audiusSdk.users.searchUsers({ query: userHandle })
    if (users.length === 0) {
      if (userHandle.length === WALLET_ADDRESS_LENGTH) {
        setToWalletAddr(userHandle)
        setIsModalOpen(true)
      } else {
        setIsNoResults(true)
      }
    } else {
      setUserList(users)
    }
  }, [userHandle])

  // Stop page reload, but form submit will still fire Search button click.
  const handleSearchFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  const handleUserTileClick = (wallet: string) => {
    setToWalletAddr(wallet)
    setIsModalOpen(true)
  }

  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.faucetHeader}>STAGING FAUCET</h1>
        <form className={styles.searchForm} onSubmit={handleSearchFormSubmit}>
          <div className={styles.searchInputs}>
            <div className={styles.searchLeft}>
              <label htmlFor='userHandle' className={styles.label}>
                User Handle:{' '}
              </label>
              <label htmlFor='amount' className={styles.label}>
                Amount:{' '}
              </label>
            </div>
            <div className={styles.searchRight}>
              <input
                name='userHandle'
                id='userHandle'
                type='text'
                onChange={handleUserHandleChange}
                placeholder='Handle or Wallet'
                maxLength={100}
                value={userHandle}
                className={styles.searchInput}
              />
              <input
                name='amount'
                id='amount'
                type='number'
                min='1'
                max={WAUDIO_CAP}
                onChange={handleAmountChange}
                value={amount}
                className={styles.searchInput}
              />
            </div>
          </div>
          <div className={styles.searchButton}>
            <Button
              onClick={handleSearchClick}
              type={ButtonType.PRIMARY}
              size={ButtonSize.MEDIUM}
              text='Search'
              className={styles.searchButton}
            />
          </div>
        </form>
        <div className={styles.alerts}>
          {isCapped ? (
            <p>The amount of wAudio you are trying to send is too damn high!</p>
          ) : null}
          {isFloored ? <p>You must send more than 0 wAudio!</p> : null}
          {isNoResults ? <p>No user with that handle exists</p> : null}
        </div>
        <div className={styles.userList}>
          {userList
            ? userList.map((user) => (
                <UserTile
                  user={user}
                  onClick={() => handleUserTileClick(user.spl_wallet)}
                  key={user.id}
                />
              ))
            : null}
        </div>
        <ConfirmModal
          isOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          amount={amount}
          toWalletAddr={toWalletAddr}
          sendWaudio={() => sendWaudio(amount, toWalletAddr)}
        />
      </div>
    </>
  )
}

export default App
