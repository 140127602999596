const FAUCET_BACKEND_URL = process.env.REACT_APP_FAUCET_BACKEND_URL

export const sendWaudio = async (amount: number, toWalletAddr: string) => {
  try {
    const response = await fetch(
      FAUCET_BACKEND_URL +
        `/api/send/?amount=${amount}&toWalletAddr=${toWalletAddr}`
    )
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    const responseData = await response.json()
    return responseData
  } catch (error) {
    console.error(error)
    throw new Error('Server error')
  }
}
